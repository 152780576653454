*,
html {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.division {
  flex-grow: 1;
}

.row-display {
  display: flex;
  flex-direction: row;
}

.center {
  justify-content: center;
}

button {
  width: 100%;
  height: 80px;
}

.container .col,
.col-1,
.col-2,
.col-3,
.col-6,
.col-4,
.col-5 {
  padding: 0px;
}

.pay {
  color: blue;
  font-size: larger;
  font-weight: bold;
}

.row {
  display: flex;
}

.white-space {
  background-color: white;
  height: 32vh;
}

/*Element specific CSS*/

.green {
  border: 5px solid black;
  background-color: black;
}

.green .row .col button {
  background-image: linear-gradient(#adff2f, #32cd32);
}

.red {
  padding: 0px 20px 0px 20px;
}

.red .row .col button {
  color: white;
  font-weight: bold;
  background-color: darkred;
}

.orange {
  border: 5px solid blue;
  background-color: blue;
}

.orange .row .col button {
  font-weight: bold;
  background-image: linear-gradient(#ffa500, #ff4500);
}

.cl {
  height: max-content;
}

.number-orange {
  background-color: orange;
}

.table-head div {
  color: white;
  padding: 0px 10px;
  border: 1px solid white;
}

.num button {
  font-size: large;
  font-weight: bold;
}

.num .operations {
  background-image: linear-gradient(#00bfff, #191970);
}

.bill {
  padding: 5px;
  border: 1px solid black;
}
